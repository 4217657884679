import { register, getPingoByCoordinates, login, getPingoByUserId} from "@/http";
import { Pingo } from "@/models/Pingo";
import {defineStore} from "pinia";
import { useJwt } from '@vueuse/integrations/useJwt';
import User from "@/models/User";



export const useUserStore = defineStore(
    "user", {
        state: () => ({
            user: new User(
                "",
                "",
                "",
                "",
                "",
                "",)
            ,

    
            coords: undefined as GeolocationPosition | undefined,
            isLoggedIn: false,
            autKey: "",
            isLoading: false,

            nearPingos: [] as Pingo[],
            myPingos: [] as Pingo[],
        }),

        getters: {
            getUser: (state) => state.user,
            getToken: (state) => state.autKey,
            getEmail: (state) => state.user.email,
            getName: (state) => state.user.userName,
            getCoords(state: any): GeolocationPosition {
                return state.coords
            },
            getPingo: (state) => state.nearPingos,
            getMyPingos: (state) => state.myPingos,
            getLoading(state: any): boolean {
                return state.isLoading
            },
            getIsLoggedIn(state: any): boolean {
                return state.isLoggedIn
            }
        },

        actions: {
            decodeJwt() {
                const token = localStorage.getItem("token");
                if (token) {
                    const {payload, header} = useJwt(token);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.user.id =  payload.value?.id;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.user.userName = payload.value?.userName;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.user.email = payload.value?.email;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    try {
                    this.fetchUserPingos();
                    } catch (error) {
                        console.error('Error fetching userPingos:', error);
                    }

                }
            },


            setCoords(position: GeolocationPosition): void {
                this.coords = position;
            },
            logout(): void {
                this.isLoggedIn = false;
                localStorage.removeItem("token");
                this.user = new User("", "", "", "", "", "");
            },

            async registerUser(user: any): Promise<any> {
                await register(user.firstName, user.lastName, user.userName, user.email, user.password);
            },
            async loginUser(userName: string, password: string): Promise<any> {
                this.isLoggedIn = true;
                const res = await login(userName, password); 
                localStorage.setItem("token", res.accessToken);
                this.decodeJwt();
            },
            async fetchUserPingos(): Promise<any> {
                try {
                    this.myPingos = await getPingoByUserId(this.user.id as string)
                } catch (error) {
                    console.error('Error fetching myPingos:', error);

                }
            },

            fetchPingo() {
                try {
                    this.$state.isLoading = true
                    getPingoByCoordinates(this.getCoords.coords.latitude, this.getCoords.coords.longitude, 2000)
                        .then((res) => {
                            this.nearPingos = res;
                        })
                        .catch((error) => {
                            console.log('nearPingos error', error)
                        })
                        .finally(() => this.$state.isLoading = false);

                } catch (error) {
                    console.error('Error fetching pingo:', error);
                }
            },
        }
    },
);

