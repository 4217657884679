import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/HomePage.vue'
import { useUserStore } from '@/stores/userStore';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import('@/views/Home.vue')
  },
  {
    name: 'mapDetail',
    path: '/mapDetail/:id',
    component: () => import('@/views/MapDetail.vue'),
  },
  {
    path: '/map',
    component: () => import('@/views/MapPage.vue')
  },
  {
    path: '/list',
    component: () => import('@/views/ListPage.vue')
  },
  {
    path: '/profile',
    component: () => import('@/views/UserProfilePage.vue')
  },
  {
    path: '/createPingo',
    component: () => import('@/views/CreatePingoPage.vue')
  },
  {
    path: '/createPingo/uebersicht',
    component: () => import('@/components/PinGoGeneral.vue')
  },
  {
    path: '/createPingo/stationen',
    component: () => import('@/components/PinGoStationen.vue')
  },
  {
    path: '/createPingo/teilnehmer',
    component: () => import('@/components/PinGoTeilnehmer.vue')
  },
  {
    path: '/createStation',
    component: () => import('@/views/CreateStationPage.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/RegisterPage.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/join',
    component: () => import('@/views/JoinPage.vue')
  },
  



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


/*
router.beforeEach((to, from, next) => {

 
  if (to.path === '/profile' || to.path === '/createPingo') {
    console.log('checking if logged in' + useUserStore().isLoggedIn)
    if (useUserStore().isLoggedIn) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }


});*/

export default router
