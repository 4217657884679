import axios from 'axios'
import {Pingo} from '@/models/Pingo';
import { useUserStore } from '@/stores/userStore';

const http = axios.create({
    baseURL: 'https://pingo-backend.mobile.media.fhstp.ac.at/'
})

/*
http.interceptors.request.use(
    (config) => {
      const userStore = localStorage.getItem("token");
      if (userStore){
        config.headers.Authorization = `Bearer ${userStore}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );




  http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );*/

async function login(userName: string, password: string) {
    const response = await http.post('/auth/login', {
        userName,
        password
    })
    console.log(response.data)
    console.log(useUserStore().isLoggedIn)
    useUserStore().isLoggedIn = true
    return response.data
}

async function register(firstName: string, lastName: string, userName:string, email: string, password: string, ) {
    console.log('registering user', firstName, lastName, userName, email, password)
    const response = await http.post('/auth/register', {
        firstName,
        lastName,
        userName,
        email,
        password
    })
    console.log(response.data)
    return response.data
}

async function getUserDetails() {
    const response = await http.get('/user')
    return response.data
}

async function postPingo(pingo: Pingo) {
    const response = await http.post('/pingo', pingo)
        .then((res) => {
            console.log('res', res)
            return res
        }).catch((error) => {
            console.log('error', error)
        })
    console.log('CREATE PINGO', response)
}

async function putPingo(pingo: Pingo) {
    console.log('putPingo', pingo)
    const response = await http.put('/pingo', pingo)
        .then((res) => {
            console.log('res', res)
            return res
        }).catch((error) => {
            console.log('error', error)
        })
    console.log('UPDATE PINGO', response)
}

async function patchUserDetails(name: any, email: any, password: any) {
    const response = await http.patch('/user', {
        name,
        email,
        password
    })
    return response.data
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:8100';

async function getPingoByCoordinates(lat: number, lng: number, radius: number): Promise<any> {
    const response = await http.get('/pingo/' + lat + '/' + lng + '/' + radius)
    return response.data
}

async function getPingoByUserId(userId: string): Promise<any> {
    const response = await http.get('/pingo/byId/' + userId)
    return response.data
}

async function getPingoBId(pingoId: string): Promise<any> {
    const response = await http.get('/pingo/byId/' + pingoId);
    return response.data;
}


export {login, register, getUserDetails, patchUserDetails, getPingoByCoordinates, postPingo, putPingo, getPingoByUserId, getPingoBId}
