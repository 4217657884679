
import { IonApp, IonModal, IonRouterOutlet, IonIcon, IonButton } from "@ionic/vue";
import QrScanner from "@/components/QrScanner.vue";
import { person, qrCode } from "ionicons/icons";
import { defineComponent } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { useUserStore } from "@/stores/userStore";


export default defineComponent({
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
      
    };
  },
  components: { IonApp, IonRouterOutlet, IonModal, IonIcon, IonButton, QrScanner },
  created() {
    if(!this.locationHasBeenLoaded && !this.geoWatcher){
      this.initGeolocationWatcher();
      this.locationHasBeenLoaded = true
      console.log('1. INIT: Geolocation has been loaded');
    }
  },
  unmounted(){
    if(this.geoWatcher){
      Geolocation.clearWatch(this.geoWatcher);
    }
  },
  data() {
    return {
      QrScanner,
      allowsGeo: true as boolean,
      person,
      geoWatcher: undefined as any,
      locationHasBeenLoaded: false,
      scan: false,
      qrCode,
    };
  },
  // if scan changes, console.log scan
  watch: {
    scan: function (val) {
      console.log(val);
    },
  },
  methods: {
    async initGeolocationWatcher() {
      const options = {
        enableHighAccuracy: true,
        maximumAge: 10000,
        timeout: 27000,
      };

      this.geoWatcher = await Geolocation.watchPosition(
        options,
        (position: any, error: GeolocationPositionError) => {
          if (error) {
            this.allowsGeo = true;
            console.log("geolocation-error", error);
            // create fallback position
            this.userStore.setCoords({
              coords: {
                latitude: 52.520008,
                longitude: 13.404954,
                accuracy: 1000,
                altitude: 0,
                altitudeAccuracy: 0,
                heading: 0,
                speed: 0,
              },
              timestamp: Date.now(),
            });
          }
          if (position) {
            console.log("fetchpingo");
            
            this.userStore.fetchPingo();
            this.allowsGeo = true;
            this.userStore.setCoords(position);
          }
        }
      );
    },
  },
});
