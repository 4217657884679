export default class User {
    //Fields 
    public id?: string
    public firstName?: string
    public lastName?: string
    public userName?: string
    public email?: string
    public password?: string
    //Constructor 
    constructor(id: string, email: string, password: string, firstName: string, userName: string, lastName: string) {
        this.id = id
        this.userName = userName
        this.firstName = firstName
        this.lastName = lastName
        this.email = email
        this.password = password
    }
}