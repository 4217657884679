<template>
 
  <div class="join-page" v-if="scannedResult === false">
   
    <div class="buttons" >
      <ion-button class="join-button" @click="startScan">
        <ion-icon slot="start" :icon="qrCodeIcon"></ion-icon>
        <ion-label>Scan QR Code</ion-label>
      </ion-button>
      <ion-button class="join-button" @click="stopScan">
        <ion-icon slot="start" :icon="qrCodeIcon"></ion-icon>
        <ion-label>Stop Scan</ion-label>
      </ion-button>
    </div>
  </div>
  
</template>


´
<script>
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

export default {
  data() {
    return {
      scannedResult: false,
      isScanning: false,
    };
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    async startScan() {
      await BarcodeScanner.showBackground();
      document.body.classList.add('qrscanner');
      this.isScanning = true;

      const result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        this.scannedResult = result.content;
        const parts = this.scannedResult.split('/');
        // get the last part of the url
        const codeId = parts[parts.length - 1];

        console.log('Code erkannt:', codeId);
        console.log('Barcode data:', result.content);
        // go to join page
        // clear route history
        this.$router.push('/join/' + codeId);
        window.history.replaceState({}, '', '/join/' + codeId);

        this.scannedResult = true;





        
      }
    },
    stopScan() {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
    },
    closeScanner() {
    this.scannedResult = true;
  },
  },
  beforeUnmount() {
    this.stopScan();
  },
};
</script>


<style scoped lang="scss">
.join-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--ion-color-primary);
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 100;
}

.loading-screen {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

.loading-spinner {
  margin-bottom: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.join-button {
  margin-bottom: 16px;

}

body.scanner-active {
  background: none;
}


</style>
